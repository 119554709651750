.article {
	padding: 30px 0;
}

@media screen and (min-width: 1200px) {
	.article {
		padding: 100px 0;
	}
}

.editor p {
	margin-bottom: 16px;
}

.editor h2,
.editor h3,
.editor h4 {
	line-height: 1.5;
}