.footer__top {
	background: #e3e7ee;
	padding-top: 75px;
	padding-bottom: 75px;
}

.footer__bottom {
	background: var(--color-brand);
}

.brands {
	border-top: 2px solid #dae1e7;
}