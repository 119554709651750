.header {
	border-bottom: 2px solid var(--color-light);
}

.navigation__link {
	color: var(--text-color);
	font-size: 1.125rem;
}

.header__a {
	right: 0;
	top: 1.875rem;
}

.navigation__element--cosmetic .navigation__link {
	border-bottom: solid 3px #b58cbf;
}

.navigation__element--nature .navigation__link {
	border-bottom: solid 3px #c4d455;
}

.navigation__element:hover .navigation__link {
	border-bottom: solid 3px var(--color-brand-light);
	background: var(--color-brand-light);
	color: #fff;
}

.navigation__element--cosmetic:hover .navigation__link {
	border-bottom: solid 3px #b58cbf;
	background: #b58cbf;
	color: #fff;
}

.navigation__element--nature:hover .navigation__link {
	border-bottom: solid 3px #c4d455;
	background: #c4d455;
	color: #fff;
}

.mm-menu {
	background: #fff;
}

.mm-listitem {
	color: var(--color-brand);
}

.mm-listitem__text {
	font-weight: bold;
	text-transform: uppercase;
}

.mm-listitem:after {
	border: none;
}

.mm-navbars_top {
	background: rgba( 255, 255, 255, 0.15 );
	border-bottom: none;
	transform: translateY(-100%);
	transition: transform 0.3s ease 0.3s;
}

.mm-wrapper_opening .mm-navbars_top {
	transform: translateY(0%);
}

.mm-navbar {
	background: none !important;
}

.mm-panels,
.mm-panels > .mm-panel {
	background: none;
}

.mm-navbar_has-btns {
	border: none;
	padding-left: 0 !important;
}

.mm-navbar__btn {
	margin-top: 16px;
}

.mm-btn_prev::before {
	left: 15px;
}

.mm-btn {
	border: none;
}

.mm-btn_next:after,
.mm-btn_close:after,
.mm-btn_close:before,
.mm-btn_prev:before {
	border-color: var(--color-brand) !important;
}

.mm-prev::before {
	left: 18px;
}

.mm-panels > .mm-panel:before {
	height: 40px;
}

.mm-listitem > a,
.mm-listitem > span {
	color: var(--color-brand);
	font-size: 1rem;
	padding: 15px 10px 15px 40px;
}

.mm-listitem__btn:not(.mm-listitem__text) {
	border: none;
}

.mm-searchfield {
	height: 60px;
	padding: 23px 0 0 20px;
	position: relative;
}

.mm-searchfield input {
	background: none !important;
	border-radius: 0 !important;
	color: var(--color-brand) !important;
	height: 45px;
}

.mm-panel__noresultsmsg {
	line-height: 1.3;
}

.logo__asset {
	height: 70px;
}