@media screen and (min-width: 1799px) {
	.wrapper {
		max-width: 1800px;
		margin: 0 auto;
	}
}

.main {
	padding-top: 119px;
}

.flex-1 {
	flex: 1 1 auto;
}