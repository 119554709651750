@font-face {
	font-family: "Lato";
	font-weight: normal;
	font-style: normal;
	src: url("../../src/fonts/Lato-Light.ttf") format("truetype");
}

@font-face {
	font-family: "Lato";
	font-weight: normal;
	font-style: italic;
	src: url("../../src/fonts/Lato-LightItalic.ttf") format("truetype");
}

@font-face {
	font-family: "Lato";
	font-weight: 600;
	font-style: normal;
	src: url("../../src/fonts/Lato-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Muli";
	font-weight: bold;
	font-style: normal;
	src: url("../../src/fonts/Lato-Bold.ttf") format("truetype");
}

body {
	font-family: "Lato", Verdana, Geneva, sans-serif;
	font-size: 16px;
	color: var(--text-color);
}

a {
	transition: all .2s cubic-bezier(.2,1,.22,1);
	color: var(--color-brand);
}

a:hover {
	color: var(--color-brand);
}

a:focus,
button:focus,
input:focus {
	outline: none;
}

.leading-loose {
	line-height: 1.5;
}

@media screen and (max-width: 767px) {
	.editor {
		hyphens: auto;
	}
}

.editor p {
	margin-bottom: 1rem;
}

h1,
h2,
h3,
h4 {
	font-family: "Lato", Verdana, Geneva, sans-serif;
	font-weight: 600;
	hyphens: auto;
	color: var(--color-brand);
}