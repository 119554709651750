form {
	margin: 0;
}

.form__radiolegend,
.form__checkboxlegend {
	margin: 0 0 .5rem .3rem;
	font-weight: bold;
	font-size: 1.125rem;
	color: #333;
}

.form__group {
	position: relative;
	margin-top: 2.25rem;
	margin-bottom: 3.25rem;
}

.form__field {
	outline: none;
	height: 2.5rem;
	font-size: 1.25rem;
	display: block;
	background: none;
	padding: .125rem .125rem .0625rem;
	border: 0 solid transparent;
	line-height: 1.5;
	width: 100%;
	color: var(--color-brand);
	box-shadow: none;
	opacity: 0.001;
	transition: opacity 0.28s ease;
	will-change: opacity;
}

.form__field--textarea {
	height: 7rem;
}

.form__bar {
	position: relative;
	height: 1px;
	background: var(--color-brand);
	display: block;
}

.form__bar::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: var(--color-brand);
	height: 2px;
	display: block;
	transform: rotateY(90deg);
	transition: transform 0.28s ease;
	will-change: transform;
}

.form__label {
	position: absolute;
	top: 0.75rem;
	line-height: 1.5rem;
	pointer-events: none;
	padding-left: 0.125rem;
	z-index: 1;
	font-size: 1rem;
	font-weight: normal;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
	color: var(--color-brand);
	transform: translateY(-50%);
	transform-origin: left center;
	transition: transform 0.28s ease, color 0.28s linear, opacity 0.28s linear;
	will-change: transform, color, opacity;
}

.form__group:focus ~ .form__bar::after {
	transform: rotateY(0deg);
}

.form__group:focus ~ .form__label {
	color: var(--color-brand);
}

.form__field:-ms-input-placeholder {
	color: var(--color-brand);
	transform: scale(0.9);
	transform-origin: left top;
}

.form__field::placeholder {
	color: var(--color-brand);
	transform: scale(0.9);
	transform-origin: left top;
}

.form__field:focus ~ .form__bar::after {
	transform: rotateY(0deg);
}

.form__field:focus ~ .form__label {
	color: var(--color-brand);
}

.form__field.-hasvalue,
.form__field:focus {
	opacity: 1;
}

.form__field.-hasvalue ~ .form__label,
.form__field:focus ~ .form__label {
	transform: translateY(-100%) translateY(-0.5em) translateY(-2px) scale(0.9);
	cursor: pointer;
	pointer-events: auto;
}

input.form__field:not(:placeholder-shown),
textarea.form__field:not(:placeholder-shown) {
	opacity: 1;
}

input.form__field:not(:placeholder-shown) ~ .form__label,
textarea.form__field:not(:placeholder-shown) ~ .form__label {
	transform: translateY(-100%) translateY(-0.5em) translateY(-2px) scale(0.9);
	cursor: pointer;
	pointer-events: auto;
}

select.form__field {
	appearance: none;
	cursor: pointer;
}

.form__placeholder {
	color: var(--color-brand);
	display: none;
}

.form__selectbar::before {
	content: "";
	position: absolute;
	height: 0.5em;
	width: 0.5em;
	border-bottom: 1px solid var(--color-brand);
	border-right: 1px solid var(--color-brand);
	display: block;
	right: 0.5em;
	bottom: .875rem;
	transition: transform 0.28s ease;
	transform: translateY(-100%) rotateX(0deg) rotate(45deg);
	will-change: transform;
}

.form__select select:focus ~ .form__bar::before {
	transform: translateY(-50%) rotateX(180deg) rotate(45deg);
}

/* RADIO FIELD */
.form__radiolabel,
.form__checkboxlabel {
	position: relative;
	cursor: pointer;
	padding-left: 1.5rem;
	text-align: left;
	color: #333;
	display: block;
	margin-bottom: 0.5rem;
}

.form__radiolabel:hover i,
.form__checkboxabel:hover i {
	color: var(--color-brand);
}

.form__radiolabel span,
.form__checkboxlabel span {
	display: inline-block;
}

.form__radiolabel input,
.form__checkboxlabel input {
	width: auto;
	opacity: 0.0001;
	position: absolute;
	left: 0.25rem;
	top: 0.25rem;
	margin: 0;
	padding: 0;
}

.form__radiobutton {
	position: absolute;
	left: 0;
	cursor: pointer;
	display: block;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: var(--color-brand);
}

.form__radiobutton::before,
.form__radiobutton::after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	margin: 0.25rem;
	width: 1rem;
	height: 1rem;
	transition: transform 0.28s ease, color 0.28s ease;
	border-radius: 50%;
	border: 0.125rem solid currentColor;
	will-change: transform, color;
}

.form__radiobutton::after {
	transform: scale(0);
	background-color: var(--color-brand);
	border-color: var(--color-brand);
}

.form__radiofield:checked ~ .form__radiobutton::after {
	transform: scale(0.5);
}

.form__radiofield:checked ~ .form__radiobutton::before {
	color: var(--color-brand);
}

.form__info {
	right: -26px;
	top: 3px;
}

.form__checkboxbutton {
	position: absolute;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: block;
	color: var(--color-brand);
	left: 0;
	top: 0.25rem;
	width: 1rem;
	height: 1rem;
	z-index: 0;
	border: 0.125rem solid currentColor;
	border-radius: 0.0625rem;
	transition: color 0.28s ease;
	will-change: color;
}

.form__checkboxbutton::before,
.form__checkboxbutton::after {
	position: absolute;
	height: 0;
	width: 0.2rem;
	background-color: var(--color-brand);
	display: block;
	transform-origin: left top;
	border-radius: 0.25rem;
	content: "";
	transition: opacity 0.28s ease, height 0s linear 0.28s;
	opacity: 0;
	will-change: opacity, height;
}

.form__checkboxbutton::before {
	top: 0.65rem;
	left: 0.38rem;
	transform: rotate(-135deg);
	box-shadow: 0 0 0 0.0625rem #fff;
}

.form__checkboxbutton::after {
	top: 0.3rem;
	left: 0;
	transform: rotate(-45deg);
}

.form__checkboxfield:checked ~ .form__checkboxbutton {
	color: var(--color-brand);
}

.form__checkboxfield:checked ~ .form__checkboxbutton::after,
.form__checkboxfield:checked ~ .form__checkboxbutton::before {
	opacity: 1;
	transition: height 0.28s ease;
}

.form__checkboxfield:checked ~ .form__checkboxbutton::after {
	height: 0.5rem;
}

.form__checkboxfield:checked ~ .form__checkboxbutton::before {
	height: 1.2rem;
	transition-delay: 0.28s;
}

.form__hide {
	max-height: 0;
	overflow: hidden;
}

.form__show {
	max-height: inherit;
}

.form__button button {
	text-transform: uppercase;
	font-weight: 800;
	padding: .5rem 1.5rem;
	display: inline-block;
	text-decoration: none;
	letter-spacing: 0;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
	background: var(--color-brand);
	color: #fff;
	transition: opacity .45s cubic-bezier(.25,1,.33,1), transform .45s cubic-bezier(.25,1,.33,1), border-color .45s cubic-bezier(.25,1,.33,1), color .45s cubic-bezier(.25,1,.33,1), background-color .45s cubic-bezier(.25,1,.33,1), box-shadow .45s cubic-bezier(.25,1,.33,1), -webkit-transform .45s cubic-bezier(.25,1,.33,1);
}