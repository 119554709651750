.dropdown-hover {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	z-index: -1;
	transition: all .3s;
	width: 22rem;
	left: -11rem;
	margin-left: 50%;
}

.dropdown-hover__toggle:hover > .dropdown-hover {
	z-index: 10;
	visibility: visible;
	opacity: 1;
}

.dropdown-hovermenu {
	background: var(--color-brand);
	margin-top: 1rem;
	position: relative;
}

.dropdown-hovermenu:before {
	display: inline-block;
	width: 0;
	height: 0;
	border-bottom: .75rem solid var(--color-brand);
	border-right: .625rem solid transparent;
	border-left: .625rem solid transparent;
	content: "";
	position: absolute;
	left: 50%;
	margin-left: -.625rem;
	top: -.75rem;
}

.dropdown-hovermenu li {
	width: 100%;
}

.dropdown-hovermenu--alt li {
	font-weight: 400;
	font-size: 1rem;
	padding: 1rem;
	display: block;
}

.dropdown-hovermenu li a {
	font-weight: 400;
	font-size: 1rem;
	padding: 1rem;
	display: block;
	background: var(--color-brand);
	color: #ffffff;
}