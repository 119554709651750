.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: 700;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	white-space: nowrap;
	padding: 6px 40px;
	font-size: 20px;
	line-height: 1.428571429;
	border-radius: 30px;
	user-select: none;
}

.btn-primary {
	background: var(--color-brand);
	color: #fff;
}

.btn-primary:hover {
	background: var(--color-light);
	color: var(--color-brand);
}

.btn i {
	margin-right: 1rem;
}