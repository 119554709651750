.deine-apotheke {
	width: 175px;
}

@media screen and (max-width: 1199px) {
	.deine-apotheke {
		right: 16px;
	}
}

.card__content ul {
	padding-left: 24px;
}

.card__content ul li {
	line-height: 1.6;
	list-style: none;
}

.card__content ul li:before {
	content: "\f00c";
	font-family: "Font Awesome 5 Light";
	display: inline-block;
	margin-left: -1.3em;
	width: 1.3em;
}

.brand-slider .slick-slide {
	height: inherit;
}

.editor ul {
	padding-left: 24px;
}

.editor ul li {
	line-height: 1.6;
	list-style: none;
}

.editor ul li:before {
	content: "\f00c";
	font-family: "Font Awesome 5 Light";
	display: inline-block;
	margin-left: -1.3em;
	width: 1.3em;
}